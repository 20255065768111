<template>
  <div v-if="configJson">
    <!-- <domain-carousel
      :config-data="configJson[systemLanguage].carousel"
    /> -->

    <domain-banner @search-domain="submitSearchDomain" />

    <domain-search-list
      v-show="pageDomainState.domainSearchList.length > 0"
      ref="DomainSearchList"
    />

    <domain-price />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useDomainFun } from './useDomain'
import useStoreModule from './useStoreModule'
import DomainBanner from './DomainBanner.vue'
import DomainSearchList from './DomainSearchList.vue'
import DomainPrice from './DomainPrice.vue'
// import DomainCarousel from './DomainCarousel.vue'

export default {
  components: {
    // DomainCarousel,
    DomainBanner,
    DomainSearchList,
    DomainPrice,
  },
  computed: {
    systemLanguage() {
      return this.$store.state.appConfig.system.language
    },

    pageDomainState() {
      return this.$store.state['page-domain']
    },
  },
  methods: {
    // (觸發)查詢網域
    submitSearchDomain() {
      this.$refs.DomainSearchList.submitSearchDomainState()
    },
  },
  setup() {
    // 註冊模組
    const DOMAIN_PAGE_STORE_MODULE_NAME = 'page-domain'

    if (!store.hasModule(DOMAIN_PAGE_STORE_MODULE_NAME)) store.registerModule(DOMAIN_PAGE_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_PAGE_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_PAGE_STORE_MODULE_NAME)
    })

    const {
      configJson,
      getDomainProductListData,
    } = useDomainFun()

    fetch('/twhost/front/config.json')
      .then(response => response.json())
      .then(data => {
        configJson.value = data.home
      })

    getDomainProductListData()

    return {
      configJson,
      getDomainProductListData,
    }
  },
}
</script>

<style>

</style>
