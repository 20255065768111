import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const { branch } = $themeConfig.app

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    searchDomain: '',
    domainProductList: [],
    domainSearchList: [],
    cartData: [],
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_LIST_PRODUCT_DOMAIN_STATE(state, val) {
      state.domainProductList = val
    },
    UPDATE_LIST_SEARCH_DOMAIN_STATE(state, val) {
      state.domainSearchList = val
    },
    UPDATE_CART_DATA_STATE(state, val) {
      state.cartData = val
    },
  },
  actions: {
    // (查詢)網域商品
    getDomainProductListData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${branch}/domain`, { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)網域
    setDomainCheck(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`${branch}/domain`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
