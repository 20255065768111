<template>
  <div class="page-domain-price">
    <h1>price</h1>

    {{ pageDomainState.domainProductList }}
  </div>
</template>

<script>
export default {
  computed: {
    pageDomainState() {
      return this.$store.state['page-domain']
    },
    // searchListTableFilter() {
    //   return this.searchListTable.filter(item => item.isShow)
    // },
    // searchCurrentDomain() {
    //   return this.searchListTable.find(item => item.isCurrent)
    // },
    // searchRecommendDomain() {
    //   return this.searchListTable.find(item => item.isRecommend)
    // },
  },
}
</script>

<style lang="scss" scoped>
.page-domain-price {
  // background-color: rgba(134, 198, 243, 0.39);
  height: 100vh;
  h1 {
    color: white;
  }
}
</style>
