import { ref } from '@vue/composition-api'
import {
  useCommenSettings, useAlert, // useTableComponent,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useDomainSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    modeState: [{
      error: '無服務', create: '可註冊', trans: '可轉移', loading: '加載中',
    }, {
      error: 'danger', create: 'info', trans: 'warning', loading: 'light-secondary',
    }],
  }

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useApiCartFun = () => {
  const getCartListData = () => store.dispatch('api-cart/getCartListData')
  const setCartCreate = (...arg) => store.dispatch('api-cart/setCartCreate', ...arg)
  const setCartUpdate = (...arg) => store.dispatch('api-cart/setCartUpdate', ...arg)
  const setCartListDelete = (...arg) => store.dispatch('api-cart/setCartListDelete', ...arg)

  return {
    getCartListData,
    setCartCreate,
    setCartUpdate,
    setCartListDelete,
  }
}

export const useDomainFun = () => {
  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const configJson = ref(null)

  const searchListTable = ref([])
  const searchListCart = ref([])
  const searchCurrentDomain = ref(null)
  const searchRecommendDomain = ref(null)

  const blankDomainProductPriceData = {
    id: null,
    ssid: null,
    suffix: null,
    // is_free: false,
    is_fire: false,
    price: 0,
    // price_new: [],
    // price_trans: [],
  }

  // (網域)拆解
  const processDomain = domain => {
    const resolveData = domain.toLowerCase()
    const specialCharPattern = /[^a-z0-9\u4e00-\u9fff.]/g

    if (resolveData.includes('.')) {
      const parts = resolveData.split('.')
      const prefix = parts.shift().replace(specialCharPattern, '')
      const remainder = `.${parts.join('.')}`
      return [prefix, remainder]
    }
    if (!resolveData.includes('.')) {
      const cleanedDomain = resolveData.replace(specialCharPattern, '')
      return [cleanedDomain, null]
    }
    return [domain, null]
  }

  const transDomainName = (name, array) => {
    if (!name) return null
    return array.find(item => item.suffix === name) || null
  }

  const transDomainSSID = (ssid, array) => {
    if (!ssid) return null
    return array.find(item => item.ssid === ssid) || null
  }

  // (查詢)網域
  const setDomainCheck = (...arg) => store.dispatch('page-domain/setDomainCheck', ...arg) // domain_suffix_ssid/domain_prefix

  // (查詢)網域商品
  const getDomainProductListData = () => {
    store.dispatch('page-domain/getDomainProductListData')
      .then(response => {
        const { data } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainProductPriceData, item),
            price_new: [],
            price_trans: [],
          }
          if (item.price_new && Array.isArray(item.price_new)) {
            resolve.price_new = item.price_new.map(option => {
              const resultPrice = {
                price: parseInt(option.price, 10),
                quantity: parseInt(option.quantity, 10),
              }
              return resultPrice
            })
            resolve.price = resolve.price_new[0].price
          }

          if (item.price_trans && Array.isArray(item.price_trans)) {
            resolve.price_trans = item.price_trans.map(option => {
              const resultPrice = {
                price: parseInt(option.price, 10),
                quantity: parseInt(option.quantity, 10),
              }
              return resultPrice
            })
          }
          return resolve
        })
        store.commit('page-domain/UPDATE_LIST_PRODUCT_DOMAIN_STATE', resolveData)
      })
  }

  return {
    configJson,
    useAlertToast,
    useHttpCodeAlert,
    syncObject,

    searchCurrentDomain,
    searchRecommendDomain,
    searchListTable,
    searchListCart,
    processDomain,
    transDomainName,
    transDomainSSID,
    setDomainCheck,
    getDomainProductListData,
  }
}
