<template>
  <div class="page-domain-search">
    <b-container>
      <div
        v-if="searchListTable.length"
        class="promote-area"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="product-promote">
              <div class="product-promote-header">
                完美搜尋
              </div>

              <div>
                <div
                  v-if="searchCurrentDomain"
                  class="product-promote-item"
                >
                  <div class="product-promote-item-content">
                    <div>
                      <div class="content-main">
                        <a>{{ searchCurrentDomain.prefix }}{{ searchCurrentDomain.product_info.suffix }}</a>

                        <img
                          v-if="searchCurrentDomain.product_info.is_fire"
                          :src="require(`@/assets/images/pages/shop/hot-deal.svg`)"
                          class="content-main-img"
                          height="28"
                          width="28"
                        >
                      </div>

                      <div class="content-quantity">
                        <v-select
                          v-if="searchCurrentDomain.state === 'create'"
                          v-model="searchCurrentDomain.selected"
                          :options="searchCurrentDomain.priceOption"
                          :clearable="false"
                          label="quantity"
                          placeholder="價格資訊"
                          @input="changeCartQuantity(searchCurrentDomain ,searchCurrentDomain.selected)"
                        >
                          <!-- @input="() => searchCurrentDomain.quantity = searchCurrentDomain.selected.quantity" -->
                          <!-- :disabled="searchCurrentDomain.isInCart" -->
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無價格資料</small>
                            </div>
                          </template>

                          <template v-slot:option="option">
                            <div class="font-weight-bold d-block text-nowrap mb-0">
                              {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                            </div>
                          </template>

                          <template #selected-option="option">
                            <div class="font-weight-bold d-block text-nowrap mb-0">
                              {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>

                    <div>
                      <div class="content-info">
                        <div class="content-state">
                          <b-badge
                            :variant="ui.modeState[1][searchCurrentDomain.state]"
                            class="state-badge"
                          >
                            {{ ui.modeState[0][searchCurrentDomain.state] }}
                          </b-badge>
                        </div>

                        <div class="content-price">
                          <div v-if="searchCurrentDomain.state === 'create'">
                            <span v-if="searchCurrentDomain.selected">
                              $ {{ parseInt(searchCurrentDomain.selected.price, 10).toLocaleString() }} &nbsp;<small>NTD</small> / {{ parseInt(searchCurrentDomain.selected.quantity, 10).toLocaleString() }} 年
                            </span>

                          </div>

                          <div v-show="searchCurrentDomain.state === 'loading'">
                            <b-img
                              :src="$store.state.app.themeImages.loadingImg"
                              rounded
                              fluid
                              height="30"
                              width="30"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="content-action">
                        <button
                          v-if="searchCurrentDomain.state === 'trans' && !searchCurrentDomain.isInCart"
                          class="trans-button"
                          :disabled="searchCurrentDomain.state === 'error' || searchCurrentDomain.isBusy"
                          @click="addDomainToCart(searchCurrentDomain)"
                        >
                          <div v-show="!searchCurrentDomain.isBusy">
                            <span class="button-text">
                              <feather-icon
                                icon="RepeatIcon"
                                size="14"
                                style="margin-top: -3px;"
                              />
                              立即轉移
                            </span>
                          </div>

                          <div v-show="searchCurrentDomain.isBusy">
                            <b-spinner small />
                          </div>
                        </button>

                        <button
                          v-else
                          class="cart-button"
                          :class="{ 'cart-button-clicked': searchCurrentDomain.isInCart, 'cart-button-loading': searchCurrentDomain.isBusy }"
                          :disabled="searchCurrentDomain.state === 'error' || searchCurrentDomain.isBusy"
                          @click="addDomainToCart(searchCurrentDomain)"
                        >
                          <div v-show="!searchCurrentDomain.isBusy">
                            <span class="button-text add">
                              <feather-icon
                                icon="PlusIcon"
                                size="14"
                                style="margin-top: -3px;"
                              />
                              購物車
                            </span>
                            <span class="button-text added">已加入</span>
                            <span
                              v-if="searchCurrentDomain.state === 'error'"
                              class="button-text"
                            >
                              不可用
                            </span>

                            <feather-icon
                              icon="ShoppingCartIcon"
                              class="cart"
                              size="22"
                            />

                            <feather-icon
                              icon="BoxIcon"
                              class="box"
                              size="10"
                            />
                          </div>

                          <div v-show="searchCurrentDomain.isBusy">
                            <b-spinner small />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-show="!searchCurrentDomain"
                  class="product-promote-loading"
                >
                  <div class="product-promote-loading-area">
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      fluid
                      height="60"
                      width="60"
                    />
                  </div>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="product-promote">
              <div class="product-promote-header">
                最佳推薦
              </div>

              <div>
                <div
                  v-if="searchRecommendDomain"
                  class="product-promote-item"
                >
                  <div class="product-promote-item-content">
                    <div>
                      <div class="content-main">
                        <a>{{ searchRecommendDomain.prefix }}{{ searchRecommendDomain.product_info.suffix }}</a>

                        <img
                          v-if="searchRecommendDomain.product_info.is_fire"
                          :src="require(`@/assets/images/pages/shop/hot-deal.svg`)"
                          class="content-main-img"
                          height="28"
                          width="28"
                        >
                      </div>

                      <div class="content-quantity">
                        <!-- && !searchRecommendDomain.isInCart -->
                        <v-select
                          v-if="searchRecommendDomain.state === 'create'"
                          v-model="searchRecommendDomain.selected"
                          :options="searchRecommendDomain.priceOption"
                          :clearable="false"
                          label="quantity"
                          placeholder="價格資訊"
                          @input="changeCartQuantity(searchRecommendDomain ,searchRecommendDomain.selected)"
                        >
                          <!-- @input="() => searchRecommendDomain.quantity = searchRecommendDomain.selected.quantity" -->
                          <!-- \ :disabled="searchRecommendDomain.isInCart" -->
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無價格資料</small>
                            </div>
                          </template>

                          <template v-slot:option="option">
                            <div class="font-weight-bold d-block text-nowrap mb-0">
                              {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                            </div>
                          </template>

                          <template #selected-option="option">
                            <div class="font-weight-bold d-block text-nowrap mb-0">
                              {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>

                    <div>
                      <div class="content-info">
                        <div class="content-state">
                          <b-badge
                            :variant="ui.modeState[1][searchRecommendDomain.state]"
                            class="state-badge"
                          >
                            {{ ui.modeState[0][searchRecommendDomain.state] }}
                          </b-badge>
                        </div>

                        <div class="content-price">
                          <div v-if="searchRecommendDomain.state === 'create'">
                            <span v-if="searchRecommendDomain.selected">
                              $ {{ parseInt(searchRecommendDomain.selected.price, 10).toLocaleString() }} &nbsp;<small>NTD</small> / {{ parseInt(searchRecommendDomain.selected.quantity, 10).toLocaleString() }} 年
                            </span>

                          </div>

                          <div v-show="searchRecommendDomain.state === 'loading'">
                            <b-img
                              :src="$store.state.app.themeImages.loadingImg"
                              rounded
                              fluid
                              height="30"
                              width="30"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="content-action">
                        <button
                          v-if="searchRecommendDomain.state === 'trans' && !searchRecommendDomain.isInCart"
                          class="trans-button"
                          :disabled="searchRecommendDomain.state === 'error' || searchRecommendDomain.isBusy"
                          @click="addDomainToCart(searchRecommendDomain)"
                        >
                          <div v-show="!searchRecommendDomain.isBusy">
                            <span class="button-text">
                              <feather-icon
                                icon="RepeatIcon"
                                size="14"
                                style="margin-top: -3px;"
                              />
                              立即轉移
                            </span>
                          </div>

                          <div v-show="searchRecommendDomain.isBusy">
                            <b-spinner small />
                          </div>
                        </button>

                        <button
                          v-else
                          class="cart-button"
                          :class="{ 'cart-button-clicked': searchRecommendDomain.isInCart, 'cart-button-loading': searchRecommendDomain.isBusy }"
                          :disabled="searchRecommendDomain.state === 'error' || searchRecommendDomain.isBusy"
                          @click="addDomainToCart(searchRecommendDomain)"
                        >
                          <div v-show="!searchRecommendDomain.isBusy">
                            <span class="button-text add">
                              <feather-icon
                                icon="PlusIcon"
                                size="14"
                                style="margin-top: -3px;"
                              />
                              購物車
                            </span>
                            <span class="button-text added">已加入</span>
                            <span
                              v-if="searchRecommendDomain.state === 'error'"
                              class="button-text"
                            >
                              不可用
                            </span>

                            <feather-icon
                              icon="ShoppingCartIcon"
                              class="cart"
                              size="22"
                            />

                            <feather-icon
                              icon="BoxIcon"
                              class="box"
                              size="10"
                            />
                          </div>

                          <div v-show="searchRecommendDomain.isBusy">
                            <b-spinner small />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-show="!searchRecommendDomain"
                  class="product-promote-loading"
                >
                  <div class="product-promote-loading-area">
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      fluid
                      height="60"
                      width="60"
                    />
                  </div>
                </div>
              </div>

            </div>

          </b-col>
        </b-row>
      </div>
    </b-container>

    <!-- 查詢列表 -->
    <b-container class="mb-2">
      <div
        v-if="searchListTableFilter.length"
        class="product-list animate__animated animate__fadeIn"
      >
        <div class="product-list-header">
          結果
        </div>

        <div
          v-for="(item, index) in searchListTableFilter"
          :key="index"
          class="product-list-item"
        >

          <img
            v-if="item.product_info.is_fire"
            :src="require(`@/assets/images/pages/shop/hot-deal.svg`)"
            class="product-list-item-img"
            height="32"
            width="32"
          >

          <div class="product-list-item-content">
            <div class="item-content-left">
              <div class="content-main">
                <a>{{ item.prefix }}{{ item.product_info.suffix }}</a>
                <!-- {{ item.isShow }} / {{ item.isCurrent }} / {{ item.isRecommend }} -->
              </div>

              <div class="content-quantity">
                <v-select
                  v-if="item.state === 'create'"
                  v-model="item.selected"
                  :options="item.priceOption"
                  class="select-size-sm"
                  :clearable="false"
                  label="quantity"
                  placeholder="價格資訊"
                  @input="changeCartQuantity(item ,item.selected)"
                >
                  <!-- && !item.isInCart :disabled="item.isInCart"-->
                  <!-- :reduce="option => option.quantity" -->
                  <!-- @input="() => item.quantity = item.selected.quantity" -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無價格資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                    </div>
                  </template>
                </v-select>
                <!-- {{ item }} -->
              </div>
            </div>

            <div class="item-content-right">
              <div class="item-content-right-price">
                <div class="content-state">
                  <b-badge
                    :variant="ui.modeState[1][item.state]"
                    class="state-badge"
                  >
                    {{ ui.modeState[0][item.state] }}
                  </b-badge>
                </div>

                <div class="content-price">
                  <div v-if="item.state === 'create'">
                    <span v-if="item.selected">
                      $ {{ parseInt(item.selected.price, 10).toLocaleString() }} &nbsp;<small>NTD</small> / {{ parseInt(item.selected.quantity, 10).toLocaleString() }} 年
                    </span>

                  </div>

                  <div v-show="item.state === 'loading'">
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      fluid
                      height="30"
                      width="30"
                    />
                  </div>
                </div>
              </div>

              <div class="item-content-right-cart">
                <button
                  v-if="item.state === 'trans' && !item.isInCart"
                  class="trans-button"
                  :disabled="item.state === 'error' || item.isBusy"
                  @click="addDomainToCart(item)"
                >
                  <div v-show="!item.isBusy">
                    <span class="button-text">
                      <feather-icon
                        icon="RepeatIcon"
                        size="14"
                        style="margin-top: -3px;"
                      />
                      立即轉移
                    </span>
                  </div>

                  <div v-show="item.isBusy">
                    <b-spinner small />
                  </div>
                </button>

                <button
                  v-else
                  class="cart-button"
                  :class="{ 'cart-button-clicked': item.isInCart, 'cart-button-loading': item.isBusy }"
                  :disabled="item.state === 'error' || item.isBusy"
                  @click="addDomainToCart(item)"
                >
                  <div v-show="!item.isBusy">
                    <span class="button-text add">
                      <feather-icon
                        icon="PlusIcon"
                        size="14"
                        style="margin-top: -3px;"
                      />
                      購物車
                    </span>
                    <span class="button-text added">已加入</span>
                    <span
                      v-if="item.state === 'error'"
                      class="button-text"
                    >
                      不可用
                    </span>

                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="cart"
                      size="22"
                    />

                    <feather-icon
                      icon="BoxIcon"
                      class="box"
                      size="10"
                    />
                  </div>

                  <div v-show="item.isBusy">
                    <b-spinner small />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>

    <!-- 購物車查詢 -->
    <b-container class="mb-2">
      <div
        v-if="searchListCart.length"
        class="product-list animate__animated animate__fadeIn"
        style="z-index: 18;"
      >
        <div class="product-list-header">
          <span>購物車</span>

          <b-link
            class="product-list-header-img"
            :to="{ name: 'user-cart' }"
          >
            <img
              :src="require(`@/assets/images/pages/shop/link-cart.svg`)"
              height="20"
              width="20"
              alt="購物車"
            >
          </b-link>
        </div>

        <div
          v-for="(item, index) in searchListCart"
          :key="index"
          class="product-list-item"
          :class="item.className"
        >
          <img
            v-if="item.product_info.is_fire"
            :src="require(`@/assets/images/pages/shop/hot-deal.svg`)"
            class="product-list-item-img"
            height="32"
            width="32"
          >

          <div class="product-list-item-content">
            <div class="item-content-left">
              <div class="content-main">
                <a>{{ item.prefix }}{{ item.product_info.suffix }}</a>
                <!-- / {{ item.cartId }} -->
              </div>

              <div class="content-quantity">
                <v-select
                  v-if="item.state === 'create'"
                  v-model="item.selected"
                  :options="item.priceOption"
                  class="select-size-sm"
                  :clearable="false"
                  label="quantity"
                  placeholder="價格資訊"
                  @input="changeCartQuantity(item ,item.selected)"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無價格資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="font-weight-bold d-block text-nowrap mb-0">
                      {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                    </div>
                  </template>
                </v-select>
                <!-- {{ item }} -->
              </div>
            </div>

            <div class="item-content-right">
              <div class="item-content-right-price">
                <div class="content-state">
                  <b-badge
                    :variant="ui.modeState[1][item.state]"
                    class="state-badge"
                  >
                    {{ ui.modeState[0][item.state] }}
                  </b-badge>
                  <!-- <span v-show="item.isBusy || item.state === 'loading'">
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      fluid
                      height="30"
                      width="30"
                    />
                  </span> -->
                </div>

                <div class="content-price">
                  <div v-if="item.state === 'create'">
                    <span v-if="item.selected">
                      $ {{ parseInt(item.selected.price, 10).toLocaleString() }} &nbsp;<small>NTD</small> / {{ parseInt(item.selected.quantity, 10).toLocaleString() }} 年
                    </span>

                  </div>

                  <div v-show="item.state === 'loading'">
                    <b-img
                      :src="$store.state.app.themeImages.loadingImg"
                      rounded
                      fluid
                      height="30"
                      width="30"
                    />
                  </div>
                </div>
              </div>

              <div class="item-content-right-cart">
                <!-- 取消加入 -->
                <button
                  class="remove-button"
                  :disabled="item.isBusy"
                  @click="removeDomainToCart(index)"
                >
                  <div v-show="!item.isBusy">
                    取消加入
                  </div>

                  <div v-show="item.isBusy">
                    <b-spinner small />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>

  </div>
</template>

<script>
import {
  BSpinner, BImg, BBadge, BContainer, BRow, BCol, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { useDomainFun, useDomainSetting, useApiCartFun } from './useDomain'
import { useSwalToast } from '@/libs/mixins/index' // , useAlert

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BContainer,
    BSpinner,
    BBadge,
    BLink,

    vSelect,
  },
  mixins: [useSwalToast],
  data() {
    return {
    }
  },
  computed: {
    pageDomainState() {
      return this.$store.state['page-domain']
    },
    searchListTableFilter() {
      return this.searchListTable.filter(item => item.isShow)
    },
    searchCurrentDomain() {
      return this.searchListTable.find(item => item.isCurrent)
    },
    searchRecommendDomain() {
      return this.searchListTable.find(item => item.isRecommend)
    },
  },
  watch: {
    'pageDomainState.domainProductList': {
      handler() {
        store.commit('page-domain/UPDATE_BUSY_LOADING_STATE', false)
        this.initUserCartListData()
      },
      deep: true,
    },
  },
  beforeDestroy() {
    store.commit('page-domain/UPDATE_BUSY_LOADING_STATE', false)
    store.commit('page-domain/UPDATE_CART_DATA_STATE', [])
    store.commit('page-domain/UPDATE_LIST_SEARCH_DOMAIN_STATE', [])
  },
  methods: {
    // (加入)購物車
    addDomainToCart(item) {
      const domainIndex = this.searchListTable.findIndex(domain => domain.rId === item.rId)
      if (domainIndex === -1) return
      const domainItem = this.searchListTable[domainIndex]
      const isLoggin = useJwt.getToken()
      if (!isLoggin) {
        this.useSwalAlertCenter(false, '更新失敗', '尚未登入')
        return
      }

      if (domainItem.isBusy) return
      if (domainItem.isInCart) return
      if (!domainItem.selected) return
      if (domainItem.state !== 'create') return

      domainItem.isBusy = true

      const newCartData = {
        product: domainItem.product,
        type: 'domain_new',
        price: domainItem.selected.price,
        info: {
          prefix: domainItem.prefix,
          quantity: domainItem.selected.quantity,
        },
      }

      this.setCartCreate(newCartData)
        .then(response => {
          domainItem.cartId = response.data.data.id
          domainItem.isBusy = false
          domainItem.isInCart = true
          domainItem.className = ''

          this.searchListCart.unshift(domainItem)
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '加入失敗', error.response.data.message)
          domainItem.isBusy = false
        })
    },

    // (移除)購物車
    removeDomainToCart(index) {
      const cartItem = this.searchListCart[index]
      const name = `${cartItem.prefix}${cartItem.product_info.suffix}`
      this.useSwalAlertWarning('移除商品', `你確定要永久移除購物車商品 ${name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setCartListDelete({
              id: cartItem.cartId,
            }).then(() => {
              this.useSwalAlertCenter(true, '移除成功!', '購物車商品已移除')
              const cartIndex = this.searchListTable.findIndex(item => item.prefix === cartItem.prefix && item.product === cartItem.product)
              if (cartIndex !== -1) {
                this.searchListTable[cartIndex].isInCart = false
                this.searchListTable[cartIndex].isBusy = false
                this.searchListTable[cartIndex].cartId = null
                this.searchListTable[cartIndex].className = ''
              }

              this.$set(this.searchListCart[index], 'className', 'fly-out')
              setTimeout(() => {
                this.searchListCart.splice(index, 1)
              }, 1000)
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '移除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (初始化)購物車
    initUserCartListData() {
      const isLoggin = useJwt.getToken()
      if (!isLoggin) return

      const blankCartData = {
        id: null,
        product: null,
        type: null,
      }

      const blankDomainInfoData = {
        prefix: null,
        quantity: 1,
      }

      this.searchListCart = []
      store.dispatch('api-cart/getCartListData')
        .then(responseCart => {
          const cartLists = responseCart.data.data.data
          const resolveData = cartLists.filter(domain => domain.type === 'domain_new').map(item => {
            const resolve = {
              ...this.syncObject(blankCartData, item),
              product_type: item.type.split('_')[0],
            }
            resolve.info = {
              ...this.syncObject(blankDomainInfoData, item.info),
              // ...this.syncObject(this.blankInfoData[resolve.product_type], item.info),
              // ...this.syncObject(this.blankInfoData[resolve.type.toLowerCase()], item.info),
            }
            return resolve
          })

          store.commit('page-domain/UPDATE_CART_DATA_STATE', resolveData)

          const domainProductOptions = this.pageDomainState.domainProductList

          resolveData.forEach((cart, delay) => {
            const productInfo = this.transDomainSSID(cart.product, domainProductOptions)
            setTimeout(() => {
              const timeId = Date.now()
              const blankSearch = {
                rId: timeId,
                prefix: cart.info.prefix,
                quantity: null,
                selected: null,
                priceOption: productInfo.price_new,
                product: productInfo.ssid,
                product_info: productInfo,
                state: 'create',
                isShow: true,
                isInCart: true,
                cartId: cart.id,
                isBusy: false,
                isCurrent: false,
                isRecommend: false,
                className: '',
              }

              if (blankSearch.priceOption.length > 0 && cart.info.quantity) {
                blankSearch.selected = blankSearch.priceOption.find(item => item.quantity === cart.info.quantity)
                blankSearch.quantity = blankSearch.selected.quantity
              }

              this.searchListCart.push(blankSearch)
            }, 10 * delay)
          })
        })
    },

    // (觸發)網域搜尋
    submitSearchDomainState() {
      if (this.pageDomainState.searchDomain.length === 0) return
      if (this.pageDomainState.isBusyLoading) return
      store.commit('page-domain/UPDATE_BUSY_LOADING_STATE', true)
      const result = this.processDomain(this.pageDomainState.searchDomain)
      const domainProductOptions = this.pageDomainState.domainProductList

      // 搜尋完美符合
      const searchCurrent = () => {
        const productInfo = this.transDomainName(result[1], domainProductOptions)
        if (productInfo) return productInfo
        return domainProductOptions[0]
      }

      // 搜尋最推薦
      const searchRecommend = currentDomain => {
        // const filteredItems = this.searchListTable.filter(item => !item.isCurrent && item.state === 'create')
        // const prices = filteredItems.map(item => parseInt(item.product_info.price, 10))
        // const lowestPrice = Math.min(...prices)
        // const lowestPriceItem = filteredItems.find(item => parseInt(item.product_info.price, 10) === lowestPrice)

        // if (lowestPriceItem) {
        //   lowestPriceItem.isShow = false
        //   lowestPriceItem.isRecommend = true
        // }

        const sortPriceOptions = [...domainProductOptions]
        sortPriceOptions.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))

        if (sortPriceOptions[0].id === currentDomain.id) return sortPriceOptions[1]
        return sortPriceOptions[0]

        // const prices = domainProductOptions.map(item => parseInt(item.price, 10))
        // const lowestPrice = Math.min(...prices)
        // const lowestPriceItem = domainProductOptions.find(item => parseInt(item.price, 10) === lowestPrice)
      }

      // 搜尋普通
      const searchCommon = (index, callback) => {
        if (this.searchListTable.length === index) {
          callback()
          return
        }

        const domain = this.searchListTable[index]

        this.setDomainCheck({
          domain_prefix: domain.prefix,
          domain_suffix_ssid: domain.product,
        })
          .then(response => {
            const { data } = response.data
            const searchListTableItem = this.searchListTable[index]
            if (!data || data.mode === 'error') {
              searchListTableItem.state = 'error'
              searchListTableItem.isBusy = false
              searchCommon(index + 1, callback)
              return
            }

            const { mode, price } = data
            searchListTableItem.state = mode
            if (price && Array.isArray(price)) {
              searchListTableItem.priceOption = price.map(option => {
                const resultPrice = {
                  price: parseInt(option.price, 10),
                  quantity: parseInt(option.quantity, 10),
                }
                return resultPrice
              })

              if (searchListTableItem.priceOption.length > 0 && !searchListTableItem.selected) {
                [searchListTableItem.selected] = searchListTableItem.priceOption
                searchListTableItem.quantity = searchListTableItem.selected.quantity
              }
            }
            searchListTableItem.isBusy = false
            searchCommon(index + 1, callback)
          })
          .catch(() => {
            const searchListTableItem = this.searchListTable[index]
            searchListTableItem.state = 'error'
            searchListTableItem.isBusy = false
            searchCommon(index + 1, callback)
          })
      }

      // 初始化資料
      const pushDataIntoSearchListTable = callback => {
        this.searchListTable = []
        const currentDomain = searchCurrent()
        const recommendDomain = searchRecommend(currentDomain)

        domainProductOptions.forEach((domain, delay) => {
          setTimeout(() => {
            const timeId = Date.now()
            let blankSearch = {
              rId: timeId,
              prefix: result[0],
              quantity: null,
              selected: null,
              priceOption: [],
              product: domain.ssid,
              product_info: domain,
              state: 'loading',
              isShow: true,
              isInCart: false,
              cartId: null,
              isBusy: true,
              isCurrent: false,
              isRecommend: false,
              className: '',
            }
            //   // 是否在購物車中
            const isLoggin = useJwt.getToken()
            if (isLoggin) {
              if (this.searchListCart.some(item => item.prefix === blankSearch.prefix && item.product === blankSearch.product)) {
                const cartItem = this.searchListCart.find(item => item.prefix === blankSearch.prefix && item.product === blankSearch.product)
                // blankSearch.isShow = false
                // blankSearch.isInCart = true
                // blankSearch.cartId = cartItem.cartId
                blankSearch = { ...cartItem }
                // return
              }
            }

            // 是否符合
            if (currentDomain.ssid === domain.ssid) {
              blankSearch.isShow = false
              blankSearch.isCurrent = true
            }

            // 是否是推薦
            if (recommendDomain.ssid === domain.ssid) {
              blankSearch.isShow = false
              blankSearch.isRecommend = true
            }

            this.searchListTable.push(blankSearch)
            if (delay + 1 === domainProductOptions.length) {
              callback()
            }
          }, 10 * delay)
        })
      }

      pushDataIntoSearchListTable(() => {
        store.commit('page-domain/UPDATE_LIST_SEARCH_DOMAIN_STATE', this.searchListTable)
        searchCommon(0, () => {
          store.commit('page-domain/UPDATE_BUSY_LOADING_STATE', false)
        })
      })
    },

    // (變更)購物車數量
    changeCartQuantity(cartItem, selected) {
      const isLoggin = useJwt.getToken()
      if (!isLoggin) return
      const domainIndex = this.searchListCart.findIndex(domain => domain.cartId === cartItem.cartId)
      if (domainIndex === -1) return
      const domainItem = this.searchListCart[domainIndex]

      if (domainItem.isBusy) return
      domainItem.isBusy = true

      const updateCartData = {
        id: cartItem.cartId,
        data: {
          price: selected.price,
          info: {
            prefix: cartItem.prefix,
            quantity: selected.quantity,
          },
        },
      }

      this.setCartUpdate(updateCartData)
        .then(() => {
          domainItem.isBusy = false
          this.useSwalAlertCenter(true, '更新成功', '購物車商品已更新')
          // this.initUserCartListData()
          domainItem.selected = selected

          if (this.searchListTable.some(item => item.prefix === domainItem.prefix && item.product === domainItem.product)) {
            const tableItem = this.searchListTable.find(item => item.prefix === domainItem.prefix && item.product === domainItem.product)
            tableItem.selected = selected
          }
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '更新失敗', error.response.data.message)
          domainItem.isBusy = false
          this.initUserCartListData()
        })
    },
  },
  setup() {
    const cartUI = store.state['api-cart'].ui

    const {
      ui,
      syncObject,
    } = useDomainSetting()

    const {
      getCartListData,
      setCartCreate,
      setCartUpdate,
      setCartListDelete,
    } = useApiCartFun()

    const {
      searchListTable,
      searchListCart,
      processDomain,
      transDomainName,
      transDomainSSID,
      setDomainCheck,
    } = useDomainFun()

    return {
      cartUI,
      ui,
      syncObject,

      // useApiCartFun
      getCartListData,
      setCartCreate,
      setCartUpdate,
      setCartListDelete,

      // useDomainFun
      // searchCurrentDomain,
      // searchRecommendDomain,
      searchListTable,
      searchListCart,
      processDomain,
      transDomainName,
      transDomainSSID,
      setDomainCheck,
      // getUserCartListData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// .edit-col-from-group {
//   .vs__dropdown-toggle {
//     border-radius: 5px 0 0 5px;
//   }
// }
</style>

<style lang="scss" scoped>
// @import '~@core/scss/base/bootstrap-extended/include';
// @import '~@core/scss/base/components/include';
$break-tablet: 992px;
// $primary-darker-color: #deedff;
// $card-main-color: #ffffff;
// $primary-color: #1bacfa;
// $primary-darker-color: #184d6f;
$table-color: #185684;
$table-header-color: #ffffff;
$primary-color: #82cfee;
$primary-darker-color: #009ef3;

.webp .page-domain-search {
  background-image: url('~@/assets/images/background/shop/domain-search.webp');
}
.no-webp .page-domain-search {
  background-image: url('~@/assets/images/background/shop/domain-search.png');
}

.page-domain-search {
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // filter: brightness(50%);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.promote-area {
  max-width: 992px;
  position: relative;
  left: 50%;
  z-index: 20;
  transform: translateX(-50%);

  .product-promote {
    border-radius: 5px;
    // min-height: 300px;
    border: 1px solid $table-color;
    margin-bottom: 30px;

    &-header {
      color: $table-header-color;
      background-color: $table-color;
      font-size: 20px;
      font-weight: bold;
      padding: 10px 20px;
    }

    &-item {
      color: #767676;
      border: 1px solid $primary-color;
      background: #FCFCFC;
      padding:20px;
      // position: relative;
      border-radius: 0 0 5px 5px;

      &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 200px;
        .content-main {
          color: $primary-darker-color;
          font-weight: bolder;
          text-decoration: none;
          font-size: 20px;
          word-break: break-all;
          white-space: pre-wrap;
          word-wrap: break-word;
          line-height: 1.2;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          &:hover {
            color: $primary-color;
          }
        }

        .content-quantity {
          margin-top: 10px;
          width: 100%;
        }

        .content-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .content-state {
            display: flex;
            align-items: center;
            .state-badge {
              padding: 10px;
              border-radius: 10px;
            }
          }

          .content-price {
            display: flex;
            align-items: center;
            justify-content: end;
            color: #333;
            font-weight: bold;
            font-size: 18px;
            white-space: nowrap;
          }
        }

        .content-action {
          margin-top: 25px;
          .cart-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            // -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            width: 100%;
            color: #fff;
            height: 40px;
            background: $primary-darker-color;
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }

            &:hover {
              background: $primary-color;
            }

            &:disabled {
              background-color: #e8e8e8;
              color: #a8a8a8;
              cursor: default;
              .add {
                display: none;
              }
              .added {
                display: none;
              }
            }

            .cart {
              position: absolute;
              z-index: 2;
              top: 50%;
              left: -10%;
              transform: translate(-50%, -50%);
              // font-size: 30px;
              color: #fff;
            }

            .box {
              position: absolute;
              z-index: 3;
              top: -20%;
              left: 52%;
              transform: translate(-50%, -50%);
              // font-size: 14px;
              color: #fff;
            }

            .button-text {
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 3;
              transform: translate(-50%, -50%);
            }

            .add {
              opacity: 1;
            }

            .added {
              opacity: 0;
            }
          }

          .cart-button-clicked {
            background-color: $primary-color;
            .cart {
              animation: cart 1.5s ease-in-out forwards;
            }

            .box {
              animation: box 1.5s ease-in-out forwards;
            }

            .add {
              animation: txt1 1.5s ease-in-out forwards;
            }

            .added {
              animation: txt2 1.5s ease-in-out forwards;
            }
            @keyframes cart {
              0% {
                left: -10%;
              }
              40%, 60% {
                left: 50%;
              }
              100% {
                left: 110%;
              }
            }

            @keyframes box {
              0%, 40% {
                top: -20%;
              }
              60% {
                top: 40%;
                left: 52%;
              }
              100% {
                top: 40%;
                left: 112%;
              }
            }

            @keyframes txt1 {
              0% {
                opacity: 1;
              }
              20%, 100%  {
                opacity: 0;
              }
            }

            @keyframes txt2 {
              0%, 80% {
                opacity: 0;
              }
              100%  {
                opacity: 1;
              }
            }
          }

          .cart-button-loading {
            background-color: $primary-color; // #89b6ee
            cursor: auto;
            &:active {
              --scale: 1;
            }
          }

          .trans-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            // -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            width: 100%;
            color: $primary-darker-color;
            height: 35px;
            border: 1px solid $primary-darker-color;
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }
            &:hover {
              background: $primary-darker-color;
              color: #fff;
            }
            .button-text {
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 3;
              transform: translate(-50%, -50%);
            }
          }

          .remove-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            -webkit-tap-highlight-color: transparent;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            width: 100%;
            color: #82868B;
            height: 35px;
            border: 1px solid #82868B;
            // background-color: rgba(130, 134, 139, 0.12);
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }
            &:disabled {
              background-color: #e8e8e8;
              border: none;
              cursor: default;
            }
          }
        }
      }
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
    }
  }
}

.product-list {
  background-color: $table-color;
  padding: 1px;
  border-radius: 5px;
  max-width: 992px;

  position: relative;
  z-index: 19;
  left: 50%;
  transform: translateX(-50%);

  .product-list-header {
    color: $table-header-color;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;

    &-img {
      transition: transform 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .product-list-item {
    color: #767676;
    border: 1px solid $primary-color;
    background: #FCFCFC;
    padding:20px;
    position: relative;
    // border-radius: 5px;
    display: flex;

    &-img {
      @media screen and (min-width: $break-tablet) {
        // display: block;
        top: 20px;
        left: 20px;
        width: 32px;
      }
      // display: none;
      position: absolute;
      top: 20px;
      left: 20px;
      width: 20px;
    }

    &-content {
      width: 100%;
      padding-left: 50px;

      display: flex; // 使用 Flexbox 布局
      justify-content: space-between; // 确保左右两边平分空间
      .item-content-left {
        flex: 40%;

        .content-main {
          color: $primary-darker-color;
          font-weight: bolder;
          text-decoration: none;
          font-size: 16px;
          max-width: 400px;
          word-break: break-all;
          white-space: pre-wrap;
          word-wrap: break-word;
          line-height: 1.2;
          overflow: hidden;
          &:hover {
            color: $primary-color;
          }
        }

          .content-quantity {
            margin-top: 5px;
            width: 100%;
          }
      }

      .item-content-right {
        flex: 60%;
        display: flex;
        justify-content: space-between;
        &-price {
          width: 100%;
          margin-right: 10px;
          // flex: 1;

          display: flex;
          justify-content: space-between;

          .content-state {
            display: flex;
            align-items: center;
            margin-left: 20px;
            .state-badge {
              padding: 10px;
              border-radius: 10px;
            }
          }

          .content-price {
            display: flex;
            align-items: center;
            justify-content: end;
            color: #333;
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            // margin-left: 10px;
            // margin-top: 10px;
          }
        }

        &-cart {
          margin-left: 10px;
          display: flex;
          justify-content: end;

          align-items: center;
          .cart-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            // -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            min-width: 144px;
            color: #fff;
            height: 35px;
            background: $primary-darker-color;
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }

            &:hover {
              background: $primary-color;
            }

            &:disabled {
              background-color: #e8e8e8;
              color: #a8a8a8;
              cursor: default;
              .add {
                display: none;
              }
              .added {
                display: none;
              }
            }

            .cart {
              position: absolute;
              z-index: 2;
              top: 50%;
              left: -10%;
              transform: translate(-50%, -50%);
              // font-size: 30px;
              color: #fff;
            }

            .box {
              position: absolute;
              z-index: 3;
              top: -20%;
              left: 52%;
              transform: translate(-50%, -50%);
              // font-size: 14px;
              color: #fff;
            }

            .button-text {
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 3;
              transform: translate(-50%, -50%);
            }

            .add {
              opacity: 1;
            }

            .added {
              opacity: 0;
            }
          }

          .cart-button-clicked {
              background-color: $primary-color;
              .cart {
                animation: cart 1.5s ease-in-out forwards;
              }

              .box {
                animation: box 1.5s ease-in-out forwards;
              }

              .add {
                animation: txt1 1.5s ease-in-out forwards;
              }

              .added {
                animation: txt2 1.5s ease-in-out forwards;
              }
              @keyframes cart {
                0% {
                  left: -10%;
                }
                40%, 60% {
                  left: 50%;
                }
                100% {
                  left: 110%;
                }
              }

              @keyframes box {
                0%, 40% {
                  top: -20%;
                }
                60% {
                  top: 40%;
                  left: 52%;
                }
                100% {
                  top: 40%;
                  left: 112%;
                }
              }

              @keyframes txt1 {
                0% {
                  opacity: 1;
                }
                20%, 100%  {
                  opacity: 0;
                }
              }

              @keyframes txt2 {
                0%, 80% {
                  opacity: 0;
                }
                100%  {
                  opacity: 1;
                }
              }
            }

          .cart-button-loading {
            background-color: $primary-color; // #89b6ee
            cursor: auto;
            &:active {
              --scale: 1;
            }
          }

          .trans-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            // -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            min-width: 144px;
            color: $primary-darker-color;
            height: 35px;
            border: 1px solid $primary-darker-color;
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }
            &:hover {
              background: $primary-darker-color;
              color: #fff;
            }
            .button-text {
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 3;
              transform: translate(-50%, -50%);
            }
          }

          .remove-button {
            position: relative;
            border: none;
            background: none;
            padding: 8px 10px;
            border-radius: 8px;
            -webkit-tap-highlight-color: transparent;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            min-width: 144px;
            color: #82868B;
            height: 35px;
            border: 1px solid #82868B;
            // background-color: rgba(130, 134, 139, 0.12);
            transform: scale(var(--scale, 1));
            transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
            &:active {
              --scale: .95;
            }
            &:disabled {
              background-color: #e8e8e8;
              border: none;
              cursor: default;
            }
          }
        }
      }
    }

    @media screen and (max-width: $break-tablet) {
      &-content {
        padding-left: 30px;
        .item-content-left {
          flex: 50%;
        }
        .item-content-right {
          flex: 50%;
          flex-direction: column;
        }
      }
    }

    @media screen and (max-width: 576px) {
      &-content {
        flex-direction: column;

        .item-content-left {
          flex: 50%;
          margin-bottom: 20px;
        }

        .item-content-right {
          &-price {
            margin-bottom: 10px;
            .content-state {
              margin: 0;
            }
          }

          &-cart {
            margin: 0;
            .cart-button {
              width: 100%;
            }
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
      border-color: $primary-color;
      background-color: #fafafaef;
      // border: 1px solid #2f75ca;
      // border-radius: 5px;

      img {
        animation: btn-breathe .8s linear infinite;
      }
    }

    &:nth-last-child(1) {
      border-radius: 0 0 5px 5px;
    }
  }

  .fly-out {
    transition: transform 1s, opacity 1s;
    transform: translateX(100%);
    opacity: 0;
  }
}

</style>
