<template>
  <div>
    <div class="banner">
      <div class="banner-bg">
        <picture>
          <source
            :srcset="require('@/assets/images/background/shop/domain-banner.webp')"
            type="image/webp"
          >
          <img
            :src="require('@/assets/images/background/shop/domain-banner.png')"
            alt="網域購買"
          >
        </picture>
      </div>

      <div class="banner-container">
        <div class="content-title">
          <h1>
            即時搜尋完美的域名
            <!-- {{ pageDomainState.searchDomain }} -->
            <!-- pageDomainState.domainProductList -->
          </h1>
          <h2>
            使用我們的網域檢查工具為您的線上專案找到完美的名稱。
          </h2>
        </div>

        <div class="content-search">

          <div class="world-wide-web">
            www.
          </div>

          <div class="search-input">
            <input
              v-model="pageDomainState.searchDomain"
              type="text"
              placeholder="輸入您想要的域名..."
              @input="filterInput"
              @keyup.enter="$emit('search-domain')"
            >
            <p>每個符合資格的網域註冊均包含免費的 WHOIS 隱私保護。</p>
          </div>

          <div class="search-action">
            <button
              class="action-btn"
              :disabled="pageDomainState.searchDomain.length === 0"
              @click="$emit('search-domain')"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    pageDomainState() {
      return this.$store.state['page-domain']
    },
  },
  methods: {
    // (網域)字串過濾
    filterInput() {
      // const specialCharPattern = /[^a-z0-9\u4e00-\u9fff.]/gi
      // // const specialCharPattern = /[^a-zA-Z0-9\u4e00-\u9fff.]/g
      // let cleanInput = this.pageDomainState.searchDomain.toLowerCase().replace(specialCharPattern, '')

      // const firstDotIndex = cleanInput.indexOf('.')
      // if (firstDotIndex !== -1) {
      //   cleanInput = cleanInput.slice(0, firstDotIndex + 1) + cleanInput.slice(firstDotIndex + 1).replace(/\./g, '')
      // }

      // this.pageDomainState.searchDomain = cleanInput

      const specialCharPattern = /[^a-z0-9\u4e00-\u9fff.]/gi
      let cleanInput = this.pageDomainState.searchDomain.toLowerCase().replace(specialCharPattern, '')

      const firstDotIndex = cleanInput.indexOf('.')

      if (firstDotIndex !== -1) {
        // 如果有点，确保第一个点前的字符数不超过63个
        if (firstDotIndex > 63) {
          cleanInput = cleanInput.slice(0, 63) + cleanInput.slice(firstDotIndex)
        }
      }

      if (firstDotIndex === -1 && cleanInput.length > 63) {
        cleanInput = cleanInput.slice(0, 63)
      }

      // 确保只允许两个点，多余的点会被移除
      const dotCount = (cleanInput.match(/\./g) || []).length
      if (dotCount > 2) {
        const parts = cleanInput.split('.')
        cleanInput = `${parts.slice(0, 2).join('.')}.${parts.slice(2).join('').replace(/\./g, '')}`
      }

      this.pageDomainState.searchDomain = cleanInput
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '~@core/scss/base/bootstrap-extended/include';
// @import '~@core/scss/base/components/include';

$primary-color: #82cfee;
$primary-darker-color: #009ef3;

.banner {
  position: relative;
  display: flex;
  height: 70vh;
  width: 100%;
  .banner-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    order: 1;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #01030a;
    picture, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      // background: #89b6ee11;
      background: rgba(111, 255, 231, 0.2);
    }
  }
  .banner-container {
    position: relative;
    z-index: 2;
    // order: 0;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .content-title {
      text-align: center;
      margin-bottom: 30px;
      h1 {
        font-size: clamp(2rem, 5vw, 2.8rem);
        font-weight: 600;
        color: #fff;
        margin: 0;
      }
      h2 {
        font-size: clamp(1.2rem, 16px, 2rem);
        font-weight: 300;
        line-height: 1.5;
        color: #f5f5f5;
      }
    }

    .content-search {
      display: flex;
      justify-content: start;
      width: 60vw;
      .world-wide-web {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        letter-spacing: 3px;
      }

      .search-input {
        padding: 0 20px;
        width: 100%;
        input {
          border: 2px solid $primary-color;
          border-radius: 40px;
          height: 50px;
          padding: 0 16px;
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          background-color: #ffffff;
          outline: none;
          &::placeholder {
            color: #ccc;
          }
        //   &:focus
        //     border-color: $blue
        //     outline: none
        //     &[data-cursor]::after
        //       display: block
        //       @media screen and (max-width: 736px)
        //         display: none
        //     + h1
        //       color: $blue
        //       span
        //         border-color: $blue
        }

        p {
          margin-top: 10px;
          font-size: 12px;
          font-weight: bold;
          color: $primary-color;
          text-align: center;
          letter-spacing: 1px;
        }
      }

      .search-action {
        .action-btn {
          display: inline-block;
          padding: 8px 28px;
          font-size: 18px;
          font-weight: bold;
          // text-transform: uppercase;
          color: #fff;
          background-image: linear-gradient(to bottom right, #00e9d0, #82cfee);
          border: none;
          border-radius: 40px;
          box-shadow: 0px 4px 0px #89b6ee;
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 6px 0px #89b6ee;
          }

          &:active {
            transform: translateY(0px);
            box-shadow: 0px 2px 0px #89b6ee;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .banner-container {
      .content-search {
        flex-direction: column;
        width: 100%;
        .world-wide-web {
          display: none;
          // font-size: 20px;
        }
        .search-input {
          padding: 0;
        }
        .search-action {
          text-align: center;
          .action-btn {
            margin-top: 10px;
            width: 100%;
            padding: 8px 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
